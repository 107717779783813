import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Dr. Kunkel and his staff welcome you to our office, and we thank you for choosing us as your healthcare provider. We are committed to delivering your care in the most considerate and professional manner. Please understand that payment of your bill is considered a part of your treatment. The following is a statement of our financial policy, which we require you to read and sign before any evaluation or treatment.`}</p>
    <h3>{`Our Financial Policy`}</h3>
    <p>{`Our office has established procedures that maintain quality care at a reasonable cost for our patients. Charges for your exam, x-rays, and any other diagnostic aids obtained during your first appointment are due at that time. These fees will be filed to your insurance. Any payments received by your insurance for these charges will be applied to your account if there is a current balance or a refund will be issued to you. If your surgery is not covered by insurance, or if you desire to have your exam and treatment performed on the same day (e.g., emergency patients), we will require payment in full by cash, credit card, personal check, or cashier’s check before any treatment. The fees quoted are an estimate only. If the procedure proves to be more complex than anticipated, the fees will be adjusted accordingly. The stated fees will be honored for six months.`}</p>
    <h3>{`Your insurance coverage`}</h3>
    <p>{`As a courtesy to you, we will file your insurance claim, and we will accept payment of insurance benefits after your first visit. However, based on what your insurance company reports to us, we do require that your estimated portion of treatment, including any deductible, be paid at the time of surgery. If your insurance company has not paid your account in full within 60 days, the account will be due, and you will be sent a statement requiring payment of the balance within the next 10 business days. Once all insurance benefits are received, we will gladly reimburse you any overpayment. We must remind our patients that insurance is a contract between you and your insurance company to pay certain amounts for medical care. Your bill is not a contract between you and your doctor, even when we file the insurance claim for you. In short, you are responsible for any and all charges not paid by your insurance carrier, and your financial obligation for payment is not dependent on insurance coverage.`}</p>
    <p>{`Regarding Managed Care Plans in which we are a participating provider, all co-pays and deductibles are due before treatment. If your insurance coverage changes to a plan in which we are not participating providers, refer to the above paragraph. If you have Medicare, you must sign a “private contract” showing you will be responsible for all charges. As a service to our patients, we are pleased to participate in the following credit plan: Care Credit and Lending Club.`}</p>
    <h3>{`Usual & customary rates`}</h3>
    <p>{`Our practice is committed to providing the best treatment for our patients, and we charge what is usual and customary for our area. You are responsible for payment regardless of any insurance company’s arbitrary “schedule” of fees, which often bears no relationship to the current standard and cost of care in this area.`}</p>
    <h3>{`Delinquent Accounts`}</h3>
    <p>{`All accounts which remain unpaid after the aforementioned period of time will be sent to our collection services. We realize that temporary financial problems may affect timely payment of your account. If such problems arise, we encourage you to contact us promptly for assistance in the management of your account.`}</p>
    <p>{`We do ask you to be on time to your appointment. If you are late, your appointment may be rescheduled at the office’s discretion. This is so we can give you the necessary attention and best possible care.`}</p>
    <p>{`A service charge of $75.00 will be added if a surgery appointment is canceled less than 24 hours from the appointment.
A service charge of $25.00 will be assessed on all returned checks.
Should it become necessary for this office to employ an attorney to enforce payment for treatment rendered, the patient agrees to pay reasonable attorney’s fees, court costs, and interest incurred for such enforcement.`}</p>
    <p>{`Thank you for taking your time to review our financial policy. Should you have any questions or concerns, we would welcome the opportunity to discuss these with you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from "../../components/layout/Layout";
import { BlueBtn } from "../../components/layout/styledComponents.js";
import FinancialPolicy from "../../components/financial-policy.mdx";
import Seo from "../../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <Seo title="Payment Policy & Dental Insurance - pikespeakoralsurgery" description="Affordable oral surgery procedures in Colorado Springs, Co." mdxType="Seo" />
  <section className="container" style={{
        margin: '128px auto'
      }}>
        <FinancialPolicy mdxType="FinancialPolicy" />
  <a href="#contact" style={{
          display: "block",
          margin: "64px auto 0",
          width: "max-content"
        }}>
    <BlueBtn mdxType="BlueBtn">Book an appointment now</BlueBtn>
  </a>
      </section>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      